import normalizeUrl, {Options} from 'normalize-url';
import {
  getDomain,
  parse,
  getHostname,
  getPublicSuffix,
  getSubdomain,
  getDomainWithoutSuffix,
} from 'tldts';

type options = Options & {
  domain?: boolean;
  hostname?: boolean;
  publicSuffix?: boolean;
  subDomain?: boolean;
  domainWithoutSuffix?: boolean;
  removePath?: boolean;
  allInformationAtUrl?: boolean;
};
export function sanitizeUrl(url: string, options?: options) {
  if (options?.allInformationAtUrl) {
    return parse(url);
  }
  if (options?.domain) {
    return getDomain(url);
  }
  if (options?.hostname) {
    const _url = normalizeUrl(url);
    return getHostname(_url);
  }
  if (options?.publicSuffix) {
    return getPublicSuffix(url);
  }
  if (options?.subDomain) {
    return getSubdomain(url);
  }
  if (options?.domainWithoutSuffix) {
    return getDomainWithoutSuffix(url);
  }
  if (options?.removePath) {
    try {
      const parsedUrl = new URL(url);
      const domain = getDomain(url);
      return `${parsedUrl.protocol}//${domain}`;
    } catch (e) {
      return null; //'Invalid URL';
    }
  }

  return normalizeUrl(url, options);
}
